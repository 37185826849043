define("ember-local-storage/helpers/storage", ["exports", "@ember/debug", "@ember/object", "@ember/application", "@ember/string", "@ember/application/deprecations"], function (_exports, _debug, _object, _application, _string, _deprecations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._buildKey = _buildKey;
  _exports._resetStorages = _resetStorages;
  _exports.getStorage = getStorage;
  _exports.storageFor = storageFor;
  _exports.tryStorage = tryStorage;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var storage = {};

  function tryStorage(name) {
    var nativeStorage; // safari private mode exposes xStorage but fails on setItem

    try {
      nativeStorage = name === 'local' ? localStorage : sessionStorage;
      nativeStorage.setItem('emberlocalstorage.test', 'ok');
      nativeStorage.removeItem('emberlocalstorage.test');
    } catch (e) {
      nativeStorage = undefined;
    }

    return nativeStorage;
  }

  function getStorage(name) {
    if (storage[name]) {
      return storage[name];
    } else {
      return storage[name] = tryStorage(name) || {};
    }
  }

  var storages = {}; // TODO: v2.0 - Remove options

  function storageFor(key, modelName) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    if (arguments.length === 2 && _typeof(modelName) === 'object') {
      options = modelName;
      modelName = null;
    }

    (false && !(_typeof(options) === 'object') && (0, _debug.assert)('The options argument must be an object', _typeof(options) === 'object')); // normalize key

    key = (0, _string.dasherize)(key);

    if (!modelName) {
      return (0, _object.computed)(function () {
        if (!storages[key]) {
          storages[key] = createStorage(this, key, null, options);
        }

        return storages[key];
      });
    }

    (false && !(typeof modelName === 'string') && (0, _debug.assert)('The second argument must be a string', typeof modelName === 'string'));
    return (0, _object.computed)(modelName, function () {
      var model = this.get(modelName); // if the propertyValue is null/undefined we simply return null/undefined

      if (!model || typeof model === 'undefined') {
        return model;
      }

      var modelKey = _modelKey(model);

      var storageKey = "".concat(key, ":").concat(modelKey); // TODO allow callbacks to delete the storage if model gets deleted

      if (!storages[storageKey]) {
        storages[storageKey] = createStorage(this, key, modelKey, options);
      }

      return storages[storageKey];
    });
  }
  /*
   * Looks up the storage factory on the container and sets initial state
   * on the instance if desired.
   */
  // TODO: v2.0 - Remove options and legacyKey


  function createStorage(context, key, modelKey, options) {
    var owner = (0, _application.getOwner)(context);
    var factoryType = 'storage';
    var storageFactory = "".concat(factoryType, ":").concat(key);
    var storageKey;

    if (options.legacyKey) {
      (false && !(false) && (0, _deprecations.deprecate)('Using legacyKey has been deprecated and will be removed in version 2.0.0', false, {
        id: 'ember-local-storage.storageFor.options.legacyKey',
        until: '2.0.0',
        url: 'https://github.com/funkensturm/ember-local-storage#deprecations'
      }));
      storageKey = options.legacyKey;
    } else {
      storageKey = modelKey ? "".concat(storageFactory, ":").concat(modelKey) : storageFactory;
    }

    storageKey = _buildKey(context, storageKey);
    var defaultState = {
      _storageKey: storageKey
    };
    var StorageFactory = owner.factoryFor(storageFactory);

    if (!StorageFactory) {
      throw new TypeError("Unknown StorageFactory: ".concat(storageFactory));
    }

    var StorageFactoryClass = StorageFactory.class;

    if (typeof StorageFactoryClass.initialState === 'function') {
      defaultState._initialContent = StorageFactoryClass.initialState.call(StorageFactoryClass, context);
    } else if (StorageFactoryClass.initialState) {
      throw new TypeError('initialState property must be a function');
    }

    if (_object.default.detect(StorageFactoryClass)) {
      return StorageFactoryClass.create(owner.ownerInjection(), defaultState);
    }

    return _object.default.create(owner.ownerInjection(), StorageFactoryClass);
  }

  function _modelKey(model) {
    var modelName = model.modelName || model.constructor.typeKey || model.constructor.modelName;
    var id = model.get('id');

    if (!modelName || !id) {
      throw new TypeError('The model must have a `modelName` and `id` on it');
    }

    return "".concat(modelName, ":").concat(id);
  } // TODO: v2.0 - Make modulePrefix the default


  function _getNamespace(appConfig, addonConfig) {
    // For backward compatibility this is a opt-in feature
    var namespace = addonConfig.namespace; // Shortcut for modulePrefix

    if (namespace === true) {
      namespace = appConfig.modulePrefix;
    }

    return namespace;
  } // TODO: Add migration helper


  function _buildKey(context, key) {
    var appConfig = (0, _application.getOwner)(context).resolveRegistration('config:environment');
    var addonConfig = appConfig && appConfig['ember-local-storage'] || {};

    var namespace = _getNamespace(appConfig, addonConfig);

    var delimiter = addonConfig.keyDelimiter || ':';
    return namespace ? "".concat(namespace).concat(delimiter).concat(key) : key;
  } // Testing helper


  function _resetStorages() {
    storages = {};
  }
});