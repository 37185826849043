define("ember-composability-tools/mixins/render-block", ["exports", "@ember/object/mixin", "@ember/object", "@ember/application", "ember-composability-tools/templates/render-block"], function (_exports, _mixin, _object, _application, _renderBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    layout: _renderBlock.default,
    fastboot: (0, _object.computed)(function () {
      var owner = (0, _application.getOwner)(this);
      return owner.lookup('service:fastboot');
    }),
    isFastBoot: (0, _object.computed)('fastboot', function () {
      return this.get('fastboot') && this.get('fastboot.isFastBoot');
    }),
    destinationElementTag: 'div',
    // creates a document fragment that will hold the DOM
    destinationElement: (0, _object.computed)(function () {
      if (!this.get('isFastBoot')) {
        return document.createElement(this.get('destinationElementTag'));
      }
    })
  });

  _exports.default = _default;
});