define("ember-composability-tools/mixins/child", ["exports", "@ember/object/mixin", "@ember/object", "@ember/utils", "ember-composability-tools/mixins/parent"], function (_exports, _mixin, _object, _utils, _parent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    // This is intended as an escape hatch, but ideally you would
    // `{{yield` a child contextual component with `parentComponent=this`
    parentComponent: (0, _object.computed)({
      get: function get() {
        if (this._parentComponent) {
          return this._parentComponent;
        }

        return this.nearestOfType(_parent.default);
      },
      set: function set(key, value) {
        return this._parentComponent = value;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      (0, _utils.tryInvoke)(this, 'initParent');
      (0, _utils.tryInvoke)(this, 'initChild');
    },
    initChild: function initChild() {
      this.registerWithParent();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (!this._isComposableDestroying) {
        this._isComposableDestroying = true;
        (0, _utils.tryInvoke)(this, 'willDestroyElementParent');
        (0, _utils.tryInvoke)(this, 'willDestroyElementChild');
      }
    },
    willDestroyElementChild: function willDestroyElementChild() {
      this._super.apply(this, arguments);

      this.unregisterWithParent();
    },
    shouldRegister: true,
    shouldRegisterToParent: function shouldRegisterToParent() {
      return this.get('shouldRegister');
    },
    destroySelfAndChildren: function destroySelfAndChildren() {
      // We may be a child-parent. Destroy children if we can.
      (0, _utils.tryInvoke)(this, 'destroyChildren');
      (0, _utils.tryInvoke)(this, 'willDestroyParent');
      this._didInsert = false;
    },
    registerWithParent: function registerWithParent() {
      var parentComponent = this.get('parentComponent');

      if (parentComponent && this.shouldRegisterToParent(parentComponent)) {
        parentComponent.registerChild(this);
      }
    },
    unregisterWithParent: function unregisterWithParent() {
      var parentComponent = this.get('parentComponent');

      if (parentComponent) {
        parentComponent.unregisterChild(this);
      }
    }
  });

  _exports.default = _default;
});