define("ember-leaflet/components/div-overlay-layer", ["exports", "@ember/object", "@ember/application", "ember-leaflet/components/base-layer", "ember-leaflet/templates/div-overlay", "ember-composability-tools"], function (_exports, _object, _application, _baseLayer, _divOverlay, _emberComposabilityTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseLayer.default.extend(_emberComposabilityTools.RenderBlockMixin, {
    layout: _divOverlay.default,
    leafletOptions: Object.freeze(['offset', 'className', 'pane']),
    fastboot: (0, _object.computed)(function () {
      var owner = (0, _application.getOwner)(this);
      return owner.lookup('service:fastboot');
    }),
    isFastBoot: (0, _object.computed)('fastboot', function () {
      return this.get('fastboot') && this.get('fastboot.isFastBoot');
    })
  });

  _exports.default = _default;
});