define("ember-leaflet/templates/lt-2-3/div-overlayable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wLC5Xjab",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-leaflet/templates/lt-2-3/div-overlayable.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});