define("ember-leaflet/templates/current/leaflet-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G7O9nYSM",
    "block": "[[[44,[[28,[37,1],null,[[\"tile\",\"wms-tile\",\"marker\",\"circle\",\"circle-marker\",\"image\",\"video\",\"polyline\",\"polygon\",\"geojson\",\"wmsTile\",\"geoJSON\"],[[50,\"tile-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"wms-tile-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"marker-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"circle-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"circle-marker-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"image-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"video-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"polyline-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"polygon-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"geojson-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"wms-tile-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"geojson-layer\",0,null,[[\"parentComponent\"],[[30,0]]]]]]]],[[[1,\"\\n\"],[41,[33,4,[\"components\"]],[[[42,[28,[37,6],[[28,[37,6],[[33,4,[\"components\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,7],[[30,1]],[[\"key\",\"value\",\"onChange\"],[[30,2,[\"as\"]],[50,[30,2,[\"name\"]],0,null,[[\"parentComponent\"],[[30,0]]]],[28,[37,8],[[30,0],[28,[37,9],[[33,10]],null]],null]]]]],[1,\"\\n\"]],[2]],null],[1,\"\\n    \"],[18,3,[[33,10]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,3,[[30,1]]],[1,\"\\n\"]],[]]]],[1]]],[1,\"\\n\"]],[\"components\",\"c\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"if\",\"emberLeaflet\",\"each\",\"-track-array\",\"ember-leaflet-assign-to\",\"action\",\"mut\",\"mergedComponents\",\"yield\"]]",
    "moduleName": "ember-leaflet/templates/current/leaflet-map.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});