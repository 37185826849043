define("ember-leaflet/templates/current/div-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ChcBFT98",
    "block": "[[[41,[51,[33,1]],[[[41,[33,3],[[[40,[[[41,[33,7],[[[1,\"        \"],[18,1,[[28,[37,9],[[30,0],[33,7]],null]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[18,1,null],[1,\"\\n\"]],[]]]],[]],\"%cursor:0%\",[28,[37,5],[[33,6]],null],null]],[]],null]],[]],null]],[\"&default\"],false,[\"unless\",\"isFastBoot\",\"if\",\"shouldRender\",\"in-element\",\"-clear-element\",\"destinationElement\",\"closePopup\",\"yield\",\"action\"]]",
    "moduleName": "ember-leaflet/templates/current/div-overlay.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});