define("ember-leaflet/services/ember-leaflet", ["exports", "@ember/service", "@ember/debug"], function (_exports, _service, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('components', []);
    },
    registerComponent: function registerComponent(name) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var components = this.get('components');
      var as = options.as || name;
      (false && !(components.find(function (c) {
        return c.name === name || c.as === as;
      }) === undefined) && (0, _debug.assert)("Tried to register component `".concat(name, "` as `").concat(as, "`, but it was already registered. Try to register a different component or register it under a different name."), components.find(function (c) {
        return c.name === name || c.as === as;
      }) === undefined));
      components.push({
        name: name,
        as: as
      });
    }
  });

  _exports.default = _default;
});