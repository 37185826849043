define("ember-leaflet/helpers/div-icon", ["exports", "@ember/component/helper", "@ember/polyfills"], function (_exports, _helper, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.divIcon = _exports.default = void 0;
  var isFastBoot = typeof FastBoot !== 'undefined';
  /* global L */

  var divIcon = isFastBoot ? function () {} : function divIcon(_, hash) {
    // https://github.com/emberjs/ember.js/issues/14668
    var options = (0, _polyfills.assign)({}, hash);
    return L.divIcon(options);
  };
  _exports.divIcon = divIcon;

  var _default = (0, _helper.helper)(divIcon);

  _exports.default = _default;
});