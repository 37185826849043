define("ember-leaflet/templates/current/div-overlayable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KLyMFlZY",
    "block": "[[[18,1,[[28,[37,1],null,[[\"popup\",\"tooltip\"],[[50,\"popup-layer\",0,null,[[\"parentComponent\"],[[30,0]]]],[50,\"tooltip-layer\",0,null,[[\"parentComponent\"],[[30,0]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-leaflet/templates/current/div-overlayable.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});